<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Orientation Batch</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-end">
                            <router-link :to="{ name: 'orientation-batch-create' }">
                                <button v-show="!loading" type="button"
                                    class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                                    v-if="hasPermissions(['module-group-create'])">
                                    <i class="mdi mdi-plus-circle"></i> Add Batch
                                </button>
                            </router-link>
                        </div>
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive" v-if="!loading">
                            <table class="table table-striped dt-responsive w-100 mb-3"
                                id="orientation-batch-list-datatable" v-if="!loading">
                                <thead v-if="!loading">
                                    <tr>
                                        <th>No.</th>
                                        <th>Orientation Batch</th>
                                        <th>Program Batch</th>
                                        <th v-if="hasPermissions(['module-group-edit'])">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="!loading">
                                    <tr v-for="(item, index) in batch" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ item.batch }}</td>
                                        <td>
                                            <span v-for="(data, index) in item.program_batch" :key="index">{{
                                                data.batch_no
                                            }}
                                                <span v-if="index + 1 < item.program_batch.length">, </span>
                                            </span>
                                        </td>
                                        <td v-if="hasPermissions(['module-group-edit'])">
                                            <span>
                                                <router-link class="action-icon" :to="{
                                                    name: 'orientation-batch-update',
                                                    params: { id: item.id },
                                                }">
                                                    <button
                                                        v-if="item.orientation_award"
                                                        type="button"
                                                        class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                                                    >
                                                        <i class="fas fa-eye"></i>
                                                        View
                                                    </button>
                                                    <i class="mdi mdi-square-edit-outline" v-else ></i>
                                                </router-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable Orientation Batch
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
    components: {
    },
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            batch: [],
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        async getAllOrientationBatch() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v2/orientation-batch`)
                .then((response) => {
                    this.batch = response.data.data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                });
            $("#orientation-batch-list-datatable").DataTable();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#orientation-batch-list-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
        this.getAllOrientationBatch();
    },
};
</script>

<style>
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>
  